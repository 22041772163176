/* eslint-disable no-template-curly-in-string */

export default {
  loaderInfo: "Loading content",
  showcase: {
    investToday: "What do you want to invest today?",
    ourShowcase: "Our showcase",
    viewDetail: "See detail",
    loading: "Loading...",
    invest: "Invest",
    createAccount: "Create account",
    seeShowcase: "See showcase",
    seeVideo: "Watch Video",
    ready: "I'm Ready",
    SEOInfo: {
      title: "Do you want to invest? Crowdfunding | a2censo",
      description:
        "Find and finance a company or SME in Colombia and invest in a2censo with the support of the Colombian stock exchange. Enter now"
    },
    toInvest: "Campaigns to invest",
    active: "Successful campaigns",
    closed: "Closed campaigns"
  },
  campaign: {
    team: "Team",
    partners: "Partners",
    knowUs: "Meet our campaign",
    ourCompany: "Our company",
    simulate: "Simulate",
    classification: "Classification",
    paymentInterestAndCapital: "Payment interest and capital",
    quarterly: "Quarterly",
    annual: "Annual",
    monthly: "Monthly",
    biannual: "Biannual",
    collectedAmount: "Collected amount",
    collected: "Collected",
    investors: "Investors",
    currency: "COP",
    goal: "Goal",
    daysToClose: "days to close",
    investingCampaigns: {
      campaigns: "Investing",
      toInvesting: "campaigns",
      pager: {
        showLess: "Show less",
        loadMore: "Load more",
        separator: "of",
        item: "Campaigns"
      }
    },
    totalCampaigns: "Total campaigns",
    description: {
      title: "Meet our campaign",
      project: "What will we do?",
      whyTrust: "Why trust our project?",
      how: "How will we make it happen?",
      risks: "Some risks",
      paymentFrequency: "Payment frequency"
    },
    info: {
      title: "Our company",
      generalInfo: "General information",
      team: "Team and partners",
      providers: "Suppliers",
      value: "Added value",
      futureProjects: "Future projection",
      financialData: "Financial data"
    },
    return: "Return"
  },
  campaignDetail: {
    shareThisCampaign: "Share this campaign:",
    shareThisCampaignMobile: "Share:",
    shareDebt:
      "This campaign is awesome!, I think you would like to invest in It.",
    shareShares:
      "I'm planning to be a shareholder of this company. ¿Would you like to join?",
    shareConvertibleNote: "Let's invest together in this campaign!",
    conditions: "Conditions",
    whatWillWeDo: "What will we do?",
    whyTrust: "Why trust Our project?",
    howWillWeDoIt: "How will we do It?",
    presentationInfo: "More information"
  },
  financialInfo: {
    rate: "Rate: fixed",
    warranty: "Warranty",
    term: "Term",
    classification: "Classification",
    interest: "Interest payment + capital",
    sector: "Sector",
    months: "months",
    tooltipFng:
      "The percentage of the campaign covered by the guarantee of the National Guarantee Fund - FNG is determined by the available quota of the recipient against the FNG and in no case implies the evaluation of the risks associated with the productive project."
  },
  anonymous: {
    connectWithA2censo: "Connect with a2censo",
    knowAll: "This way you can know all the",
    campaingAndContent: "campaigns and content we have for you."
  },
  welcome: "Hello, welcome to",
  grability: "Grability",
  not_found: "Oops! Are you sure you want to enter here?",
  terms: {
    title: "Terms and conditions",
    title2:
      "We have updated our terms and conditions. Accept them to continue.",
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              finibus neque ac massa eleifend, eget aliquet ligula sagittis.
              Nunc vulputate in nibh nec tincidunt. Mauris in blandit nisl.
              Fusce ornare, eros id facilisis tincidunt, diam nibh bibendum
              lectus, sit amet pulvinar ipsum nibh quis elit. Praesent eros
              nibh, accumsan a rhoncus vitae, auctor ac tellus. Donec volutpat
              tortor at diam ultricies tincidunt. Sed sed tempor est. Duis ac
              ultricies felis, eu tempor est. Nulla facilisi. Integer interdum
              ornare tellus tincidunt mollis. Aenean mi nisi, sodales ut erat
              at, imperdiet aliquet sapien. Quisque massa lorem, consectetur nec
              orci at, laoreet volutpat purus. Nulla facilisi. Proin viverra,
              nisl sed ornare tincidunt, urna nulla elementum augue, eget
              tristique lectus massa eget elit. Phasellus sed interdum quam.
              Maecenas elementum, urna ac ornare porta, tellus odio malesuada
              neque, in gravida sapien velit ut velit. Maecenas in velit eu
              lorem suscipit dapibus. Mauris faucibus ligula vitae semper
              ultricies. Sed ullamcorper aliquet purus sed egestas. Donec non
              ligula orci. Donec porttitor sollicitudin ipsum, eget tempor
              libero finibus ac. Ut ornare mattis ipsum sed venenatis. Phasellus
              tristique molestie urna non maximus. Ut lacinia nisi sit amet
              lectus laoreet efficitur. Mauris ultricies consectetur ligula eu
              mattis. Phasellus mi ex, tempor sit amet ullamcorper a, euismod
              vel ex. Donec in iaculis eros. Sed euismod, velit vitae eleifend
              facilisis, turpis neque sodales purus, vel porta magna nibh
              molestie sapien. Aenean condimentum, libero at feugiat congue,
              augue libero dictum quam, in pharetra enim ipsum non ante. Vivamus
              eget felis pulvinar, volutpat nunc a, lobortis tellus. Mauris
              laoreet, nisi eu venenatis tincidunt, magna risus pulvinar velit,
              quis mattis tellus est in metus. Duis nisi felis, convallis vitae
              enim ac, pulvinar tincidunt quam. Duis at risus venenatis,
              ultrices massa feugiat, finibus odio. Duis in tortor neque.
              Curabitur tempus lobortis mauris posuere sagittis. Nam sodales
              lectus justo, et mollis tellus ornare at. Aliquam lectus mi,
              hendrerit vitae euismod a, finibus nec magna. Pellentesque
              habitant morbi tristique senectus et netus et malesuada fames ac
              turpis egestas. Quisque sapien urna, cursus eu ex et, accumsan
              tempus ipsum. Sed eu ornare lacus. Morbi ut purus ut nunc rhoncus
              venenatis et non velit. Sed mollis interdum leo sit amet pulvinar.
              Mauris quis ante ex. Integer pulvinar, sapien in dictum elementum,
              ipsum leo mollis magna, non fringilla ipsum metus vel est. Sed
              quis quam laoreet, scelerisque massa porta, sodales eros. Aenean
              varius magna a dolor venenatis, ac dignissim diam vehicula.
              Aliquam vitae sem in massa bibendum ornare ut ac nisl. Sed cursus
              dignissim quam nec laoreet. Pellentesque fringilla neque sit amet
              orci maximus ullamcorper. Sed ac accumsan risus. Nulla sit amet
              nisl velit. Quisque congue, ligula vitae placerat pharetra, enim
              ante ultrices ipsum, eget blandit urna sem non sem. Aliquam
              consequat facilisis ante non consectetur. Donec convallis porta
              convallis. Praesent cursus ex sit amet neque efficitur, et laoreet
              nulla faucibus. Suspendisse tempor neque quis dui accumsan
              sodales. Ut id magna a velit varius aliquam sollicitudin id nibh.
              Maecenas imperdiet lectus sed tellus auctor tempor. In hac
              habitasse platea dictumst. Maecenas nec gravida ligula, a interdum
              tortor. Morbi convallis pharetra ligula egestas molestie. Etiam
              dignissim finibus dui id iaculis. Vivamus sollicitudin consectetur
              libero, et scelerisque orci egestas eu. Nulla pretium vel augue
              vel ullamcorper. Ut rutrum ex ac leo maximus scelerisque. Etiam
              vel eros tortor. Curabitur hendrerit dui nec nunc porttitor
              ultrices. Donec tincidunt nulla mauris, sed vestibulum felis
              rhoncus ut. Nulla sagittis ipsum odio, ac facilisis tellus
              efficitur nec. Proin tempor, mauris id semper tristique, lectus
              tortor molestie ante, non vulputate lorem orci at enim.
              Suspendisse dapibus lorem eget orci consectetur, ac mattis diam
              posuere. Curabitur lectus diam, porttitor a hendrerit et, aliquam
              et ligula. In porttitor diam justo, a sagittis velit mattis ut.
              Proin pulvinar enim vel tortor sagittis tincidunt. Donec
              sollicitudin pellentesque interdum. Nulla ullamcorper turpis vel
              bibendum semper. Quisque non elementum odio, viverra lacinia elit.
              In pulvinar tincidunt nibh vel egestas. Maecenas ultricies est eu
              nibh mattis eleifend nec at enim. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia Curae; Donec
              hendrerit finibus vulputate. Nam erat dui, iaculis at enim eget,
              gravida vestibulum risus. Vestibulum eget risus mollis, tempor
              tellus sit amet, tempor elit. Proin odio eros, pulvinar id laoreet
              quis, suscipit auctor neque. Curabitur in tellus porttitor, cursus
              justo non, congue est. Cras tincidunt ex orci, facilisis vehicula
              nibh elementum in. Curabitur pulvinar sed elit quis condimentum.
              Nam id lacus vel erat porta luctus. Suspendisse vulputate suscipit
              felis nec vulputate. Morbi non sagittis ex. Sed quis varius metus.
              Integer iaculis metus purus, at eleifend felis sollicitudin ut.
              Nunc aliquam tempor tristique. Morbi congue ullamcorper nulla, ut
              elementum odio lobortis vel. Nam feugiat mattis finibus. Integer
              vel nisi porttitor, dignissim lectus ut, convallis leo. Donec at
              commodo lectus. Suspendisse iaculis maximus interdum. Nullam in
              sem purus. In erat quam, pellentesque eu tristique ut, feugiat
              ultrices risus. In vitae orci congue, dapibus diam sit amet,
              accumsan arcu.`,
    acceptButton: "Accept",
    continue: "Continue",
    thanks:
      "Thank you for accepting the new terms and conditions. You are ready to invest in a2censo."
  },
  politics: {
    title: "Policies",
    title2:
      "We have updated our personal data treatment policy. Accept it to continue.",
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
    finibus neque ac massa eleifend, eget aliquet ligula sagittis.
    Nunc vulputate in nibh nec tincidunt. Mauris in blandit nisl.
    Fusce ornare, eros id facilisis tincidunt, diam nibh bibendum
    lectus, sit amet pulvinar ipsum nibh quis elit. Praesent eros
    nibh, accumsan a rhoncus vitae, auctor ac tellus. Donec volutpat
    tortor at diam ultricies tincidunt. Sed sed tempor est. Duis ac
    ultricies felis, eu tempor est. Nulla facilisi. Integer interdum
    ornare tellus tincidunt mollis. Aenean mi nisi, sodales ut erat
    at, imperdiet aliquet sapien. Quisque massa lorem, consectetur nec
    orci at, laoreet volutpat purus. Nulla facilisi. Proin viverra,
    nisl sed ornare tincidunt, urna nulla elementum augue, eget
    tristique lectus massa eget elit. Phasellus sed interdum quam.
    Maecenas elementum, urna ac ornare porta, tellus odio malesuada
    neque, in gravida sapien velit ut velit. Maecenas in velit eu
    lorem suscipit dapibus. Mauris faucibus ligula vitae semper
    ultricies. Sed ullamcorper aliquet purus sed egestas. Donec non
    ligula orci. Donec porttitor sollicitudin ipsum, eget tempor
    libero finibus ac. Ut ornare mattis ipsum sed venenatis. Phasellus
    tristique molestie urna non maximus. Ut lacinia nisi sit amet
    lectus laoreet efficitur. Mauris ultricies consectetur ligula eu
    mattis. Phasellus mi ex, tempor sit amet ullamcorper a, euismod
    vel ex. Donec in iaculis eros. Sed euismod, velit vitae eleifend
    facilisis, turpis neque sodales purus, vel porta magna nibh
    molestie sapien. Aenean condimentum, libero at feugiat congue,
    augue libero dictum quam, in pharetra enim ipsum non ante. Vivamus
    eget felis pulvinar, volutpat nunc a, lobortis tellus. Mauris
    laoreet, nisi eu venenatis tincidunt, magna risus pulvinar velit,
    quis mattis tellus est in metus. Duis nisi felis, convallis vitae
    enim ac, pulvinar tincidunt quam. Duis at risus venenatis,
    ultrices massa feugiat, finibus odio. Duis in tortor neque.
    Curabitur tempus lobortis mauris posuere sagittis. Nam sodales
    lectus justo, et mollis tellus ornare at. Aliquam lectus mi,
    hendrerit vitae euismod a, finibus nec magna. Pellentesque
    habitant morbi tristique senectus et netus et malesuada fames ac
    turpis egestas. Quisque sapien urna, cursus eu ex et, accumsan
    tempus ipsum. Sed eu ornare lacus. Morbi ut purus ut nunc rhoncus
    venenatis et non velit. Sed mollis interdum leo sit amet pulvinar.
    Mauris quis ante ex. Integer pulvinar, sapien in dictum elementum,
    ipsum leo mollis magna, non fringilla ipsum metus vel est. Sed
    quis quam laoreet, scelerisque massa porta, sodales eros. Aenean
    varius magna a dolor venenatis, ac dignissim diam vehicula.
    Aliquam vitae sem in massa bibendum ornare ut ac nisl. Sed cursus
    dignissim quam nec laoreet. Pellentesque fringilla neque sit amet
    orci maximus ullamcorper. Sed ac accumsan risus. Nulla sit amet
    nisl velit. Quisque congue, ligula vitae placerat pharetra, enim
    ante ultrices ipsum, eget blandit urna sem non sem. Aliquam
    consequat facilisis ante non consectetur. Donec convallis porta
    convallis. Praesent cursus ex sit amet neque efficitur, et laoreet
    nulla faucibus. Suspendisse tempor neque quis dui accumsan
    sodales. Ut id magna a velit varius aliquam sollicitudin id nibh.
    Maecenas imperdiet lectus sed tellus auctor tempor. In hac
    habitasse platea dictumst. Maecenas nec gravida ligula, a interdum
    tortor. Morbi convallis pharetra ligula egestas molestie. Etiam
    dignissim finibus dui id iaculis. Vivamus sollicitudin consectetur
    libero, et scelerisque orci egestas eu. Nulla pretium vel augue
    vel ullamcorper. Ut rutrum ex ac leo maximus scelerisque. Etiam
    vel eros tortor. Curabitur hendrerit dui nec nunc porttitor
    ultrices. Donec tincidunt nulla mauris, sed vestibulum felis
    rhoncus ut. Nulla sagittis ipsum odio, ac facilisis tellus
    efficitur nec. Proin tempor, mauris id semper tristique, lectus
    tortor molestie ante, non vulputate lorem orci at enim.
    Suspendisse dapibus lorem eget orci consectetur, ac mattis diam
    posuere. Curabitur lectus diam, porttitor a hendrerit et, aliquam
    et ligula. In porttitor diam justo, a sagittis velit mattis ut.
    Proin pulvinar enim vel tortor sagittis tincidunt. Donec
    sollicitudin pellentesque interdum. Nulla ullamcorper turpis vel
    bibendum semper. Quisque non elementum odio, viverra lacinia elit.
    In pulvinar tincidunt nibh vel egestas. Maecenas ultricies est eu
    nibh mattis eleifend nec at enim. Vestibulum ante ipsum primis in
    faucibus orci luctus et ultrices posuere cubilia Curae; Donec
    hendrerit finibus vulputate. Nam erat dui, iaculis at enim eget,
    gravida vestibulum risus. Vestibulum eget risus mollis, tempor
    tellus sit amet, tempor elit. Proin odio eros, pulvinar id laoreet
    quis, suscipit auctor neque. Curabitur in tellus porttitor, cursus
    justo non, congue est. Cras tincidunt ex orci, facilisis vehicula
    nibh elementum in. Curabitur pulvinar sed elit quis condimentum.
    Nam id lacus vel erat porta luctus. Suspendisse vulputate suscipit
    felis nec vulputate. Morbi non sagittis ex. Sed quis varius metus.
    Integer iaculis metus purus, at eleifend felis sollicitudin ut.
    Nunc aliquam tempor tristique. Morbi congue ullamcorper nulla, ut
    elementum odio lobortis vel. Nam feugiat mattis finibus. Integer
    vel nisi porttitor, dignissim lectus ut, convallis leo. Donec at
    commodo lectus. Suspendisse iaculis maximus interdum. Nullam in
    sem purus. In erat quam, pellentesque eu tristique ut, feugiat
    ultrices risus. In vitae orci congue, dapibus diam sit amet,
    accumsan arcu.`,
    acceptButton: "Accept",
    continue: "Continue",
    thanks:
      "Thank you for accepting the data processing policy. You are ready to invest in a2censo."
  },
  creditHistory: {
    title: "Credit History",
    title2:
      "We have updated the authorization to consult your credit history. Accept it to continue.",
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
    finibus neque ac massa eleifend, eget aliquet ligula sagittis.
    Nunc vulputate in nibh nec tincidunt. Mauris in blandit nisl.
    Fusce ornare, eros id facilisis tincidunt, diam nibh bibendum
    lectus, sit amet pulvinar ipsum nibh quis elit. Praesent eros
    nibh, accumsan a rhoncus vitae, auctor ac tellus. Donec volutpat
    tortor at diam ultricies tincidunt. Sed sed tempor est. Duis ac
    ultricies felis, eu tempor est. Nulla facilisi. Integer interdum
    ornare tellus tincidunt mollis. Aenean mi nisi, sodales ut erat
    at, imperdiet aliquet sapien. Quisque massa lorem, consectetur nec
    orci at, laoreet volutpat purus. Nulla facilisi. Proin viverra,
    nisl sed ornare tincidunt, urna nulla elementum augue, eget
    tristique lectus massa eget elit. Phasellus sed interdum quam.
    Maecenas elementum, urna ac ornare porta, tellus odio malesuada
    neque, in gravida sapien velit ut velit. Maecenas in velit eu
    lorem suscipit dapibus. Mauris faucibus ligula vitae semper
    ultricies. Sed ullamcorper aliquet purus sed egestas. Donec non
    ligula orci. Donec porttitor sollicitudin ipsum, eget tempor
    libero finibus ac. Ut ornare mattis ipsum sed venenatis. Phasellus
    tristique molestie urna non maximus. Ut lacinia nisi sit amet
    lectus laoreet efficitur. Mauris ultricies consectetur ligula eu
    mattis. Phasellus mi ex, tempor sit amet ullamcorper a, euismod
    vel ex. Donec in iaculis eros. Sed euismod, velit vitae eleifend
    facilisis, turpis neque sodales purus, vel porta magna nibh
    molestie sapien. Aenean condimentum, libero at feugiat congue,
    augue libero dictum quam, in pharetra enim ipsum non ante. Vivamus
    eget felis pulvinar, volutpat nunc a, lobortis tellus. Mauris
    laoreet, nisi eu venenatis tincidunt, magna risus pulvinar velit,
    quis mattis tellus est in metus. Duis nisi felis, convallis vitae
    enim ac, pulvinar tincidunt quam. Duis at risus venenatis,
    ultrices massa feugiat, finibus odio. Duis in tortor neque.
    Curabitur tempus lobortis mauris posuere sagittis. Nam sodales
    lectus justo, et mollis tellus ornare at. Aliquam lectus mi,
    hendrerit vitae euismod a, finibus nec magna. Pellentesque
    habitant morbi tristique senectus et netus et malesuada fames ac
    turpis egestas. Quisque sapien urna, cursus eu ex et, accumsan
    tempus ipsum. Sed eu ornare lacus. Morbi ut purus ut nunc rhoncus
    venenatis et non velit. Sed mollis interdum leo sit amet pulvinar.
    Mauris quis ante ex. Integer pulvinar, sapien in dictum elementum,
    ipsum leo mollis magna, non fringilla ipsum metus vel est. Sed
    quis quam laoreet, scelerisque massa porta, sodales eros. Aenean
    varius magna a dolor venenatis, ac dignissim diam vehicula.
    Aliquam vitae sem in massa bibendum ornare ut ac nisl. Sed cursus
    dignissim quam nec laoreet. Pellentesque fringilla neque sit amet
    orci maximus ullamcorper. Sed ac accumsan risus. Nulla sit amet
    nisl velit. Quisque congue, ligula vitae placerat pharetra, enim
    ante ultrices ipsum, eget blandit urna sem non sem. Aliquam
    consequat facilisis ante non consectetur. Donec convallis porta
    convallis. Praesent cursus ex sit amet neque efficitur, et laoreet
    nulla faucibus. Suspendisse tempor neque quis dui accumsan
    sodales. Ut id magna a velit varius aliquam sollicitudin id nibh.
    Maecenas imperdiet lectus sed tellus auctor tempor. In hac
    habitasse platea dictumst. Maecenas nec gravida ligula, a interdum
    tortor. Morbi convallis pharetra ligula egestas molestie. Etiam
    dignissim finibus dui id iaculis. Vivamus sollicitudin consectetur
    libero, et scelerisque orci egestas eu. Nulla pretium vel augue
    vel ullamcorper. Ut rutrum ex ac leo maximus scelerisque. Etiam
    vel eros tortor. Curabitur hendrerit dui nec nunc porttitor
    ultrices. Donec tincidunt nulla mauris, sed vestibulum felis
    rhoncus ut. Nulla sagittis ipsum odio, ac facilisis tellus
    efficitur nec. Proin tempor, mauris id semper tristique, lectus
    tortor molestie ante, non vulputate lorem orci at enim.
    Suspendisse dapibus lorem eget orci consectetur, ac mattis diam
    posuere. Curabitur lectus diam, porttitor a hendrerit et, aliquam
    et ligula. In porttitor diam justo, a sagittis velit mattis ut.
    Proin pulvinar enim vel tortor sagittis tincidunt. Donec
    sollicitudin pellentesque interdum. Nulla ullamcorper turpis vel
    bibendum semper. Quisque non elementum odio, viverra lacinia elit.
    In pulvinar tincidunt nibh vel egestas. Maecenas ultricies est eu
    nibh mattis eleifend nec at enim. Vestibulum ante ipsum primis in
    faucibus orci luctus et ultrices posuere cubilia Curae; Donec
    hendrerit finibus vulputate. Nam erat dui, iaculis at enim eget,
    gravida vestibulum risus. Vestibulum eget risus mollis, tempor
    tellus sit amet, tempor elit. Proin odio eros, pulvinar id laoreet
    quis, suscipit auctor neque. Curabitur in tellus porttitor, cursus
    justo non, congue est. Cras tincidunt ex orci, facilisis vehicula
    nibh elementum in. Curabitur pulvinar sed elit quis condimentum.
    Nam id lacus vel erat porta luctus. Suspendisse vulputate suscipit
    felis nec vulputate. Morbi non sagittis ex. Sed quis varius metus.
    Integer iaculis metus purus, at eleifend felis sollicitudin ut.
    Nunc aliquam tempor tristique. Morbi congue ullamcorper nulla, ut
    elementum odio lobortis vel. Nam feugiat mattis finibus. Integer
    vel nisi porttitor, dignissim lectus ut, convallis leo. Donec at
    commodo lectus. Suspendisse iaculis maximus interdum. Nullam in
    sem purus. In erat quam, pellentesque eu tristique ut, feugiat
    ultrices risus. In vitae orci congue, dapibus diam sit amet,
    accumsan arcu.`,
    acceptButton: "Accept",
    continue: "Continue",
    thanks:
      "Thank you for accepting the new authorization to check your credit history. You are ready to finance yourself in a2censo."
  },
  register: {
    signUpReason: {
      invest: {
        title: "I want to invest",
        description: "To grow my money."
      },
      finance: {
        title: "I want to finance",
        description: "To make my business projects come true."
      }
    },
    pymeRequirements: {
      tooltip: {
        title: `
        Some economic sectors will not be able to \nparticipate. `,
        titleBold: `
        They are restricted: `,
        description: `
        Political Movements, Gambling, Money Exchange, \nCooperatives, Credit Originators, Financial \nmediation, Religious Communities, Agriculture, Livestock, \nHunting, Forestry and Fishing, \nMining and Defense, Public Administration and Defense, Plans \nSocial security of compulsory affiliation, Extraterritorial \nnormalization activities (including NGOs), \nConstruction of civil works or housing`
      },
      send: "Continue",
      title: "PYME",
      subtitle: "Select the requirements that your company meets.",
      requirement1: "It does not belong to the restricted economic sectors",
      requirement2: "The company is constituted as a legal entity",
      requirement3:
        "It is not in the process of liquidation, cause for dissolution, business reorganization agreement or other similar specific cause"
    },
    naturalPerson: "Natural person",
    legalPerson: "Legal person",
    pymePerson: "Growing company",
    generalData: "General data",
    name: "Name",
    lastname: "First Surname",
    secondLastname: "Second Surname",
    companyName: "Corporate name",
    dv: "DV",
    documentType: "ID",
    documentNumber: "Identification number",
    confirmDocument: "Confirm identification number",
    email: "Email",
    countryCode: "Country code",
    phoneNumber: "Cell number",
    password: "Password",
    confirmPassword: "Confirm password",
    terms1: "By selecting, you accept our",
    terms2: "terms and conditions.",
    politics1: "By selecting, you accept our",
    politics2: "personal data processing policy.",
    hc1: "By selecting, you authorize us to consult your ",
    hc2: "credit history",
    continue: "Continue",
    saveAndContinue: "Save and continue",
    loading: "Loading ...",
    validations: {
      number: "Must be a numeric value",
      name: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your name"
      },
      lastname: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your first last name"
      },
      secondLastname: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your second last name"
      },
      docExpeditionDate: {
        required: "You must enter the date of issue of your document"
      },
      docExpeditionPlace: {
        required: "You must enter the place of issue of your document"
      },
      typeDoc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the type of document"
      },
      Doc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your document"
      },
      docDate: {
        required: "You must enter the date of issue document"
      },
      docCity: {
        required: "You must enter the place of issue of the document"
      },
      email: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your email",
        invalid: "Not a valid email format"
      },
      phoneCode: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the country code"
      },
      phone: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only numbers are allowed.",
        required: "You must enter your phone number"
      },
      password: {
        min: "Very Short!",
        max: "Very long!",
        matches:
          "Must have a minimum of 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character",
        required: "You must enter your password"
      },
      confirmPassword: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must confirm your password",
        oneOf: "Passwords must match"
      },
      dv: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the verification code"
      },
      confirmDocument: {
        required: "You must confirm your document number",
        oneOf: "The document number must match"
      }
    },
    userValidation: {
      understand: "It is understood",
      button: "Ok",
      errorCodes: {
        400: "At this time the requested action cannot be performed. Try again later.",
        401: "At this time the requested action cannot be performed. Try again later.",
        405: "At this time the requested action cannot be performed. Try again later.",
        416: "Remember that you cannot be part of the promotion by being an official of the BVC.",
        500: "At this time the requested action cannot be performed. Try again later. We hope it is solved very soon.",
        1002: "Remember that you cannot be part of a2censo being a shareholder of the BVC.",
        1003: "Remember that you cannot be part of a2censo as an associate shareholder of the BVC.",
        1012: "The email you are trying to enter already exists.",
        1013: "El número de documento que estás intentando ingresar ya existe.",
        "056":
          "Estamos teniendo inconvenientes con la creación de tu cuenta. Comunicate con nosotros para más información.",
        1014: "The document number you are trying to enter already exists.",
        1015: "For security your password must have at least seven (7) characters between numbers, letters and at least one (1) special character within its combination.",
        1016: "We are sorry! We are having problems. Please try another time.",
        1017: "We are sorry! We are having problems. Please try another time.",
        1018: "We are sorry! We are having problems. Please try another time.",
        1019: "We are sorry! We are having problems. Please try another time.",
        "027": "The email does not exists"
      }
    }
  },
  form: {
    email: "Email",
    firstName: "Name",
    lastName: "Surname",
    send: "Send"
  },
  header: {
    createAccount: "Create account",
    showcase: "Showcase",
    logout: "Log out",
    lastSignIn: "Last entry:",
    clientIp: "IP:",
    investments: "My investments",
    balance: "My balance",
    payments: "My payments",
    login: "Login",
    tooltipLogout: {
      areYouSure: "Are you sure you want to logout?",
      message: "Data that has not been saved will be lost.",
      continue: "No, continue.",
      confirmExit: "Yes, logout"
    }
  },
  shareholdersLabel: {
    debt: "Investors",
    shares: "Shareholders",
    convertibleNote: "Investors"
  },
  footer: {
    termAndConditions: "Terms and conditions",
    dataProcessingPolicy: "Data processing policy",
    frequencyQuestions: "Frequently asked questions",
    resources: "Resources",
    inversions: "My investments",
    contact: "Contact us",
    address: "Cra 7 No 71-21 Torre B Piso 12 Bogotá D.C.",
    phone: "(601) 313 9000 - Option 1",
    email: "a2censo@bvc.com.co",
    aProduct: "A product",
    alliance: "In alliance with",
    support: "Support",
    poweredBy: "Powered by"
  },
  codeValidation: {
    title: "Verification code",
    subtitle: "Enter the code we send to your cell phone",
    noValidCode: "Invalid code",
    noReceived: "I have not received the code.",
    askAgain: "Request again",
    newCodeMessage: "We've sent a new message"
  },
  personType: {
    whatKindOfPersonYouAre: "What type of investor are you?",
    naturalPerson: "Natural person",
    naturalPersonRules:
      "You have a Colombian ID or an immigration ID, and you have a bank account in Colombia.",
    legalPerson: "Legal person",
    legalPersonRulesInit:
      "Do you identify with a Colombian NIT or are a foreign company with a bank account in Colombia",
    pyme: "PYME",
    pymeRules: "Lorem ipsum"
  },
  oops: {
    sorry: "Sorry, it's not you",
    itsUs: "It's us.",
    tryAgain: "We are presenting some inconvenience, please try again later.",
    goHome: "Back to top"
  },
  checkEmail: {
    checkYourEmail: "Check your email!",
    completedSuccessfully: "You have completed your data successfully.",
    inYourEmail: "In your email you will find a confirmation message",
    youMustUse: "what you should check to continue",
    receivedData: "We have already received all your data and send you a ",
    messageToContinue: "message to continue."
  },
  documentUploaded: {
    title: "Document received!",
    subtitle:
      "We will soon confirm the status of your document to the email you registered, while this happens you can invest under the limits of an unqualified investor."
  },
  successView: {
    processFinishedSuccessfully: "Process successfully completed"
  },
  forgotPassword: {
    title: "Forgot your password?",
    subtitle: "Enter the email you registered in a2censo to continue.",
    continue: "Continue",
    newPassword: "New password",
    email: "Email",
    cancel: "Cancel",
    error: "This email is not registered on our platform",
    newPasswordConfirmation: "Confirm new password",
    successTitle: "Password reset!",
    successDescription:
      "Enter with your new data and explore all our campaigns in a2censo.",
    resetTitle: "Reset password",
    validations: {
      password: {
        min: "Very Short!",
        max: "Very long!",
        matches:
          "Must have a minimum of 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character",
        required: "You must enter your password"
      },
      confirmPassword: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must confirm your password",
        oneOf: "Passwords must match"
      }
    }
  },
  forgotPasswordMethod: {
    title: "Forgot your password?",
    subtitle:
      "We will send you the code to reset your password. Please choose an option to receive it",
    phoneOption: "Via email",
    emailOption: "Via SMS"
  },
  forgotPasswordOtp: {
    title: "Forgot your password?",
    subtitle: "Enter the code we have sent to your email.",
    subtitleSMS: "Enter the code we have sent to your phone.",
    askAgain: "Request again",
    noReceived: "I have not received the code.",
    intermittentService:
      "Thank you for waiting for us. In an instant we will upload the information",
    validCode: "Valid code",
    invalidCode: "Invalid code",
    expiredCode: "This code has expired",
    tooManyAttempts: "You have spent all attempts! Try again later",
    blacklisted1: "Oops!",
    blacklisted2: "Something went wrong, please try later.",
    serviceDown1: "Oops!",
    serviceDown2: "Sorry, it's not you, it's us.",
    serviceDown3: "We are presenting some inconvenience please try again later."
  },
  enrollment: {
    challengeQuestionnaireAnswers: {
      title: "Confirm your identity",
      subtitle:
        "Help us answer the following questions to confirm that it is you, and in this way you can continue",
      sendButton: "Send",
      modal: {
        title: "Sorry!",
        subtitle1: "Your answers",
        subtitle2: "do not match.",
        subtitle3: "You have spent all attempts.",
        subtitle4: "Come back later.",
        button: "Try again"
      },
      button: "Ok",
      errorCode: {
        "EVIDENTE-05":
          "At the moment we do not have enough information to corroborate your identity. Try to link later.",
        "EVIDENTE-06": "At this time we cannot validate your data. Try later.",
        "EVIDENTE-07":
          "At this time we cannot validate your identification number. Try later.",
        "EVIDENTE-08":
          "At this time we cannot validate your identification document. Try later.",
        "EVIDENTE-09": "You have spent all your attempts. Try later.",
        "EVIDENTE-10":
          "We are having technical failures. Please come back later.",
        "EVIDENTE-11":
          "We are having technical failures. Please come back later.",
        "LISTAS_RESTRICTIVAS-301":
          "We are sorry! We are having problems and we cannot continue with your vinculation.",
        "LISTAS_RESTRICTIVAS-302":
          "We are sorry! We are having problems and we cannot continue with your vinculation.",
        "LISTAS_RESTRICTIVAS-303":
          "We are having technical failures. Please come back later.",
        "GENERACION_QUESTIONARIO-00":
          "We are sorry! We are having problems, please try another time.",
        "GENERACION_QUESTIONARIO-02":
          "We are sorry! We are having problems, please try another time.",
        "GENERACION_QUESTIONARIO-07": "You must be banked to continue.",
        "GENERACION_QUESTIONARIO-10":
          "You have reached the maximum number of attempts per day. Please try again later.",
        "GENERACION_QUESTIONARIO-11":
          "You have reached the maximum number of attempts per month. Please try again later.",
        "GENERACION_QUESTIONARIO-12":
          "You have reached the maximum number of attempts per year. Please come back at another time.",
        "GENERACION_QUESTIONARIO-13":
          "You have exceeded the amount of income allowed. Please come back another time.",
        "GENERACION_QUESTIONARIO-14":
          "You have exceeded the amount of income allowed. Please come back another time.",
        "GENERACION_QUESTIONARIO-15":
          "You have exceeded the amount of income allowed. Please come back another time.",
        "GENERACION_QUESTIONARIO-17":
          "We are sorry! We are having problems, please try another time.",
        "GENERACION_QUESTIONARIO-18":
          "We are sorry! We are having problems, please try another time.",
        "GENERACION_QUESTIONARIO-19":
          "We are sorry! We are having problems, please try another time."
      }
    },
    financialInfo: {
      title: "Financial information",
      tooltipInfo: "Financial information",
      subtitle:
        "Remember that together we create trust, for that reason your financial data must be real to be able to guide you.",
      assets: "Assets",
      monthly_income: "Monthly income",
      equity: "Heritage",
      monthly_expenses: "Monthly expenses",
      profit: "Net Profit",
      saveAndContinue: "Save and continue",
      saving: "Saving ...",
      passives: "Passives"
    },
    organizationalStructure: {
      title: "Representative and partners",
      tooltipInfo: "Representative and partners",
      legal: "Legal representative",
      associates: "Partners",
      saveAndContinue: "Save and continue",
      saving: "Saving ...",
      legalRepresentative: {
        completeInfo: "Complete information"
      },
      associate: {
        add: "Add",
        participation: "participation"
      }
    }
  },
  generalInformation: {
    title: "General information",
    subTitle:
      "Tell us more about yourself! With your personal and contact information we can assure you a better experience.",
    generalData: "General data",
    name: "Name",
    lastName: "Surname",
    documentType: "Document type",
    documentIDType: "ID",
    documentNumber: "Identification number",
    email: "Email",
    countryCode: "Country code",
    phoneNumber: "Cell number",
    documentCity: "Place of issue",
    documentDate: "Issue Date",
    birthDayData: "Birth data",
    birthDay: "Date of birth",
    selectCountry: "Select the country",
    selectCity: "Select the city",
    birthDayDate: "Date of birth (yyyy-mm-dd)",
    birthDayDateConstitution: "Date of constitution (yyyy-mm-dd)",
    country: "Country",
    countryPlaceholder: "Select the country",
    city: "City",
    cityPlaceholder: "Select the city",
    state: "Department",
    statePlaceholder: "Select the department",
    address: "Address",
    emailPlaceholder: "example@domain.com",
    contactData: "Contact information",
    cityCode: "Indic. city",
    homePhone: "Phone number",
    publicResourcesManager: "Do you manage public resources?",
    USMoneyTransfer: "Do you make money transfers to the United States?",
    publicExposed: "Do you consider yourself a publicly exposed person?",
    yes: "Yes",
    no: "No",
    ciiuTitlePJ: "Do you have a secondary Economic Sector? (Optional)",
    ciiuTitlePN: "What is your economic activity? (ISIC Code)",
    ciiuPlaceholder: "Write the code (Ex. O143)",
    placeholder: {
      birthDayDateConstitution: "aaaa - mm - dd"
    },
    validations: {
      birth_country: {
        required: "You must enter your country of birth"
      },
      birth_state: {
        required: "You must enter your birth department"
      },
      birth_city: {
        required: "You must enter your city of birth"
      },
      birth_date: {
        required: "You must enter your birth date",
        valid: "It is not a valid email format"
      },
      contact_country: {
        required: "You must enter your country"
      },
      contact_state: {
        required: "You must enter your department"
      },
      city: {
        required: "You must enter your city"
      },
      address: {
        min: "Very short!",
        max: "Very long!",
        required: "You must enter your address"
      },
      phone_country_code: {
        required: "You must enter your country code"
      },
      phone_city_code: {
        notValid: "Must be a number",
        required: "You must enter your city code",
        range: "Must be between 1 and 99"
      },
      landline: {
        min: "Very short!",
        max: "Very long!",
        notNumber: "It must be a number",
        notValid: "Invalid number",
        required: "You must enter a phone number"
      },
      ciiu: {
        invalid: "ISIC is invalid",
        required: "You must enter a ISIC"
      },
      name: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your name",
        type: "Only letters are allowed."
      },
      last_name: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter last name",
        type: "Only letters are allowed"
      },
      position: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter position",
        type: "Only letters are allowed"
      },
      entity: {
        min: "Very Short!",
        max: "Very long!",
        required: "You muest enter company",
        type: "Only letters are allowed"
      }
    }
  },
  summary: {
    title: "Your summary",
    title2: "Financial information",
    title3: "What is your purpose?",
    textTitle: ", make sure all your data is correct before finalizing.",
    generalData: "General data",
    bornData: "Birth data",
    contactData: "Contact Data",
    name: "Name",
    lastname: "Surname",
    documentType: "Document type",
    documentNumber: "Identification number",
    address: "Enter your address",
    email: "Email",
    country: "Select the country",
    country2: "Country of residence",
    city_expedition: "Select the city",
    birth_city: "Select the city",
    birth_department: " Select the department",
    department: " Select the department",
    city: "Select the city",
    expedition_date: "Date of issue",
    date1: "Date",
    date2: "Date of birth",
    countryCode: "Country code",
    cityCode: "Indic. city",
    landline: "Phone number",
    phoneNumber: "Cell number",
    resources1: "Do you manage Public Resources?",
    resourcesUnitedState: "Do you turn Resources to the United States?",
    resourcesUS1: "Are you permanently empowered in the United States?",
    resourcesUS2: "Do you have permanent address in the United States?",
    resourcesUS3: "Do you have a permanent phone in the United States?",
    resourcesUS4: "Do you have shareholding in a company in the United States?",
    resourcesUS5: "Do you make money transfers in the United States?",
    publicPerson: "Do you consider yourself a Publicly Exposed Person?",
    yes: "Yes",
    no: "No",
    publicPersonType: "What type of PEP is it?",
    pep1: "PEP Type 1",
    pep2: "PEP Type 2",
    pep3: "PEP Type 3",
    familyPublicPersonType:
      "Do you have family members considered Publicly Exposed Person (PEP)?",
    familyTitle: "Family #",
    familyNames: "Names",
    familyLastnames: "Surname",
    familyPosition: "Current position",
    familyEntity: "Current entity or company",
    familyNew: "Add another family member",
    financialInfo1: "Assets",
    financialInfo2: "Monthly income",
    financialInfo3: "Equity",
    financialInfo4: "Monthly expenses",
    pourpose: "Select your purpose",
    continue: "Finish",
    loading: "Loading ...",
    ciiuTitle: "What is your economic activity? (ISIC Code)",
    ciiuPlaceholder: "Write the code (Ex. O143)",
    validations: {
      number: "Must be a numeric value",
      name: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your name",
        type: "Only letters are allowed."
      },
      lastname: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your last name"
      },
      typeDoc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the type of document"
      },
      doc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your document"
      },
      address: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your address"
      },
      email: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your email"
      },
      phoneCode: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the country code"
      },
      phone: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only numbers are allowed.",
        required: "You must enter your phone number"
      },
      country: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the country"
      },
      city: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the city"
      },
      birth_city: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the city"
      },
      ciiu: {
        invalid: "ISIC is invalid",
        required: "You must enter a ISIC"
      }
    }
  },
  summarySuccess: {
    title: "We are ready!",
    paragraph1: "From now on you can grow your money invested in projects of",
    paragraph2: "Colombian companies and be part of this great community of",
    paragraph3: "trust and collaboration.",
    paragraph4: "From now on you can grow your money",
    paragraph5: "invested in business projects",
    paragraph6: "Colombians and be part of this great",
    paragraph7: "community of trust and collaboration.",
    know: "Know them!"
  },
  confirmData: {
    titleSection: "Confirm your data",
    sectionDescription:
      ", before continuing, verify that all your data is correct. Note that you will not be able to edit them later!",
    titleForm: "General Data",
    name: "Name",
    lastname: "First Surname",
    secondLastname: "Second Surname",
    documentType: "ID",
    documentNumber: "Identification number",
    confirmDocument: "Confirm identification number",
    email: "Email",
    countryCode: "Country code",
    phoneNumber: "Cell number",
    password: "Password",
    confirmPassword: "Confirm password",
    expeditionDate: "Date of issue",
    expeditionPlace: "Place of issue",
    saveAndContinue: "Save and continue",
    DV: "Verification digit",
    existenceCertificate:
      "Upload your certificate of existence (Document in .PDF)",
    uploadDocument: "Upload document",
    ciiuTitle: "What is the Main Economic Sector of your company? (ISIC Code)",
    ciiuOptionalTitle: "Do you have a secondary Economic Sector? (Optional)",
    ciiuPlaceholder: "Write the code (Ex. O143)",
    validations: {
      number: "Must be a numeric value",
      name: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your name"
      },
      last_name: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your first last name"
      },
      second_last_name: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only letters are allowed.",
        required: "You must enter your second last name"
      },
      existence_certificate: {
        required: "You must enter your certificate of existence"
      },
      typeDoc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the type of document"
      },
      doc: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your document"
      },
      docDate: {
        required: "You must enter the date of issue document"
      },
      docCity: {
        required: "You must enter the place of issue of the document"
      },
      email: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your email"
      },
      phoneCode: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the country code"
      },
      phone: {
        min: "Very Short!",
        max: "Very long!",
        type: "Only numbers are allowed.",
        required: "You must enter your phone number"
      },
      ciiu: {
        invalid: "ISIC is invalid",
        required: "You must enter a ISIC"
      }
    },
    oopsSubtitle: "Something went wrong!",
    oopsMessage: "Please try later.",
    loading: "loading ...",
    experianErrorButton: "Ok",
    errorCode: {
      "EVIDENTE-05":
        "At the moment we do not have enough information to corroborate your identity. Try to link later.",
      "EVIDENTE-06": "At this time we cannot validate your data. Try later.",
      "EVIDENTE-07":
        "At this time we cannot validate your identification number. Try later.",
      "EVIDENTE-08":
        "At this time we cannot validate your identification document. Try later.",
      "EVIDENTE-09": "You have spent all your attempts. Try later.",
      "EVIDENTE-10":
        "We are having technical failures. Please come back later.",
      "EVIDENTE-11":
        "We are having technical failures. Please come back later.",
      "LISTAS_RESTRICTIVAS-301":
        "We are sorry! We are having problems and we cannot continue with your vinculation.",
      "LISTAS_RESTRICTIVAS-302":
        "We are sorry! We are having problems and we cannot continue with your vinculation.",
      "LISTAS_RESTRICTIVAS-303":
        "We are having technical failures. Please come back later."
    }
  },
  purpose: {
    titleSection: "Your purpose?",
    titleList: "What motivates you to invest in a2censo?",
    titleListLegal: "What motivates your company to invest?",
    saveAndContinue: "Save and continue"
  },
  login: {
    title: "Welcome back!",
    firstTime: "First time in a2censo?",
    login: "Login",
    loading: "loading...",
    forgotPassword: "Forgot your password?",
    register: "Register",
    lastSignIn: "Last entry:",
    clientIp: "IP:",
    invalidCredentials: "We don't know your data, sign up to continue.",
    tooManyAttempts:
      "Ops! Something went wrong, for your security we have suspended the account.",
    goToRegister: "Sign up",
    oopsSubtitle: "Sorry.\n your account has been deleted.",
    oopsMessage:
      "We inform you that your account has been deleted because the\n time limit to complete the process has been exceeded.\n To resume the process you just have to register again.",
    button: "Ok",
    buttonModal033: "Understand",
    errorCodes: {
      "033":
        "Check your email, there you will find a confirmation message that you should check to continue."
    }
  },
  vinculationExpired: {
    linkExpired: "This link has expired!",
    linkExpiredDescription:
      "This link has exceeded 30 days since it was sent. Register again and continue with the link in a2censo."
  },
  simulator: {
    simulate: "Simulate your investment",
    fixedFee: "Fixed rate",
    ea: "E.A",
    months: "months",
    paymentPlusCapital: "Interest payment + capital",
    howMuch: "How much would you like to invest?",
    minimum: "Minimum investment amount",
    total: "You receive a total of",
    earn: "You win",
    periodicFee: "You receive fixed rates (interest + capital) of:",
    invest: "Invest",
    monthly: "Monthly",
    quarterly: "Quarterly",
    term: "Term",
    investmentError:
      "Invalid amount: The minimum amount to invest is $ 200.000 and the increments must be multiples of $ 100,000"
  },
  associate: {
    sectionDescription:
      "Knowing the data of the partners with more than 5% participation in your company helps us size your entire structure",
    personTypeTitle: "Choose the type of person that is the partner",
    natural: "Natural person",
    company: "Legal person",
    generalDataTitle: "General data",
    name: "Name",
    companyName: "Corporate name",
    lastName: "Surname",
    companyDocumentType: "NIT",
    documentType: "ID",
    documentNumber: "Identification number",
    documentNumberRepeat: "Identification number repeated",
    dv: "DV",
    expeditionDate: "Date of issue",
    expeditionPlace: "Place of issue",
    ciiuTitle: "What is the Main Economic Sector of the company? (ISIC Code)",
    ciiu: "Write the sector or code",
    percent: "Enter the percentage of participation of this partner (Min. 5%)",
    publicResourcesManager: "Do you manage public resources?",
    USMoneyTransfer: "Do you make money transfers to the United States?",
    publicExposed: "Do you consider yourself a publicly exposed person?",
    yes: "Yes",
    no: "No",
    country: "Select the country",
    department: "Select the department",
    city: "Select the city",
    saving: "Saving ...",
    saveAndContinue: "Save and continue",
    validations: {
      number: "Must be a numeric value",
      name: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your name"
      },
      last_name: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your last name"
      },
      document_type: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the type of document"
      },
      document_number: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your document",
        repeated: "Associate repeated",
        invalid: "Invalid document"
      },
      document_date: {
        required: "You must enter the date of issue document"
      },
      expedition_place: {
        required: "You must enter the place of issue of the document"
      },
      country: {
        required: "You must select the country"
      },
      city: {
        required: "You must select the city"
      },
      reason: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter your business name"
      },
      dv: {
        min: "Very Short!",
        max: "Very long!",
        required: "You must enter the verification code"
      },
      ciiu: {
        invalid: "ISIC is invalid",
        required: "You must enter a ISIC"
      }
    },
    associateSaveErrorTitle: "Error registering partner",
    associateSaveError:
      "The associate has an error with their data and cannot be registered.",
    oopsSubtitle: "We are sorry. We cannot continue with your linking process.",
    oopsMessage:
      "Please contact us via email a2censo@bvc.com.co or our service line at PBX (601) 313 9000"
  },
  legalRepresentative: {
    title: "Legal Representative",
    subtitle:
      "Knowing the data of the legal representative of your company helps us strengthen trust in our relationship.",
    legalRepresentativeErrorTitle: "Error registering the legal representative",
    legalRepresentativeError:
      "The legal representative presents an error with your data and cannot be registered.",
    return: "Back"
  },
  notice: {
    title: "News",
    article:
      "Stock market will train entrepreneurs around the country on a2censo platform",
    date: "Posted: Sep 26, 2019"
  },
  featured: {
    title: "Our protagonists",
    collect: "Amount raised",
    titlecampaing: "Chök",
    price: "COP 20MM"
  },
  crowdExplication: {
    title: "This is how investment crowdfunding works",
    subtitle: "We make a cow",
    subtitle2: "We put it in a project",
    paragraph: "Many people join their resources"
  },
  headerVideo: {
    title: "Believing in Colombian projects is what makes us big",
    subtitle: "Are you ready to take the first step?",
    paragraph:
      "Discover the new way to grow your money by being part of the country's first investment crowdfunding community"
  },
  questions: {
    title: "Got any questions?",
    subtitle: "Leave your message and we will contact you.",
    formname: "Full Name",
    formmail: "Email",
    formdescription: "Message",
    pymeFormName: "Business name",
    pymeFormContactName: "Contact name",
    pymeFormContactPhone: "Phone",
    acceptpolitic: "",
    acceptpoliticLink:
      "By selecting, you accept our personal data processing policy.",
    acceptterms: "",
    accepttermsLink: "By selecting, you accept our terms and conditions.",
    titleMessage: "Thank you!",
    thanks:
      "Your message has been sent correctly, one of our support people will contact you via email. For now, continue exploring the platform we have created for you."
  },
  tabs: {
    buttonModalText: "I'm interested!",
    invest: "Invest",
    financeYourself: "Finance yourself",
    questions: "Doubts",
    title: "1. Register",
    paragraph:
      "With some basic information you can have your own account in acense.",
    title2: "2. Fall in love",
    paragraph2:
      "Explore the open campaigns in a2censo and select those with which you identify.",
    title3: "3. Invest from $ 200.000",
    paragraph3: "Agile and 100% digital in your favorite campaigns.",
    title4: "4. Your investment grows",
    paragraph4:
      "Start receiving your capital + interest. So you can invest in other companies.",
    title5: "1. Contact us",
    paragraph5:
      "Are you a Colombian company and have projects to finance? Write us to accompany you in the process!",
    title6: "2. Set your campaign",
    paragraph6:
      "Share your company information, project to be financed and the conditions you offer to evaluate it.",
    title7: "3. Connect with your investors",
    paragraph7:
      "We will publish your campaign in a2censo so that everyone knows you and invests in your project.",
    title8: "Make your project a reality",
    paragraph8:
      "4. Receive your money, execute your project and pay off your debt according to the conditions initially agreed.",

    question: "Why trust a2censo?",
    answer:
      "We are a debt crowdfunding platform created and backed by the Colombian Stock Exchange, we are monitored by the S.F.C. and we have strategic alliances with the main business development, innovation and entrepreneurship organizations.",

    question2: "What makes us different?",
    answer2:
      "We make sure that your money reaches the hands of Colombian businessmen, you get a differential return and you have a support of up to 50% of your investment.",

    question3: "What are the requirements to invest?",
    answer3:
      "It's very simple! You must have an incense account and have a Colombian bank account.",

    question4: "When do you start to make a profit?",
    answer4:
      "The campaigns will close once the expected amount has been reached. If the campaign closes successfully you will begin to receive your money according to the conditions agreed upon at the time of the investment.",

    question5: "How to contact a2censo?",
    answer5:
      "You can write to us at a2censo@bvc.com.co or through the platform and we will respond as soon as possible."
  },
  investmentSummary: {
    oneStepAhead: "One step closer",
    subtitle:
      " remember that bvc does not guarantee the return of the invested capital and the expected returns, since your investment in a2censo is a venture capital investment.",
    subtitle2:
      "If you agree with your investment data we will connect you with the services of ",
    subtitle3: "Transfiriendo ",
    subtitle4: "to finish the process.",
    info:
      "1. If you are not a qualified investor you can invest up to 20% of your annual income or equity, whichever is greater.",
    info2:
      "2. If you have already invested in this campaign and want to increase the amount go to - My investments - and select the option - Increase -",
    info3:
      "3. We inform you that you can only modify or cancel your investment from the business day following its completion.",
    info4:
      "4. Keep in mind that you will not be able to make modifications or cancellations of your investments:",
    info5: "i) the closing day due to the expiration of the campaign.",
    info6:
      "ii) in case of closing of the campaign to reach 100%, event that can occur any day during the term of the campaign.",
    info7: "iii) during the time the campaign is awarded.",
    campaignToInvest: "Campaign",
    summary: "Investment Summary",
    continue: "Continue",
    loading: "Loading"
  },
  investment: {
    investmentAmountTitle: "How much are you going to invest?",
    investmentAmountText1: "From any bank you can make your investment.",
    investmentAmountText2: "Campaign to invest:",
    amountToInvestmentTitle: "Amount to invest",
    additionalAmount: "Additional amount",
    amountPlaceHolder: "Enter an amount",
    continue: "Continue",
    loading: "Loading",
    updateInvestment: "How much do you want to increase?",
    validations: {
      amount: {
        invalid: "Minimum investment amount $200.000",
        invalidIncrease: "Minimum investment amount $100.000",
        invalidAmount: "Invalid amount",
        min: "You must enter an amount"
      },
      format: {
        invalid: "The file format must be PDF or JPG and must weigh less than"
      },
      fileSize: {
        invalid: "The file size can't be more than ",
        required: "The file is required."
      },
      expirationDate: {
        invalid: "Expiration date cannot be less than today"
      }
    },
    readyToInvest: "Ready to invest",
    readyToInvestSubtitle:
      "Let us know some additional information to accompany you at every step of your investment.",
    campaignToInvest: "Campaign to invest:",
    areYouQualifiedInvestor: "Are you a qualified / qualified investor?",
    uploadDocumentTypeQuestion: "Choose an option and upload your document",
    uploadDocumentLabel: "Upload document",
    expirationDate: "Expiration date",
    taxQualityQuestion: "Tax quality",
    select: "Select",
    investmentsList: {
      title: "My investments",
      subtitle:
        "Here you have the list of your investments and the corresponding status of each one.",
      date: "Investment date:",
      investTo: "Investment a",
      yourInvestment: "Your investment",
      collectedAmount: "Amount raised",
      investmentGoal: "COP target",
      collected: "collected",
      cancelInvestment: "Cancel investment",
      pending: "Pending",
      active: "Approved",
      canceled: "Canceled",
      rejected: "Rejected",
      exchange: "In exchange",
      campaignState1: "Open",
      campaignState2: "Awarded",
      campaignState3: "Deserted",
      campaignState4: "Canceled",
      campaignState5: "Inactive",
      movements: "My movements",
      placeholderMessage: "You haven't made an investment yet",
      placeholderLink: "Showcase"
    },
    tooltip: {
      title: "Remember that:",
      message1_invest:
        "1. You can invest in any campaign of a2censo from {{minimumAmount}} and from now on multiples of {{multipleAmount}}.",
      message1_increase:
        "1. Increase your investment from {{minimumAmount}} and / or with multiple values ​​of {{multipleAmount}}",
      message2:
        "2. If you are not a qualified investor you can invest up to 20% of your annual income or equity, whichever is greater.",
      message3:
        "3. You cannot be the only investor in the campaign. Remember that to grow you need at least 2.",
      message4:
        "4. If the campaign needs less than $ 400.000 to close, then you are the last investor! So you can only invest the remaining value for closing in the campaign."
    },
    info:
      "1. If you are not a qualified investor you can invest up to 20% of your annual income or equity, whichever is greater.",
    info2:
      "2. If you have already invested in this campaign and want to increase the amount go to - My investments - and select the option - Increase -",
    info3: "3. For more information consult the help button.",

    info4: "The investment you are going to make is long-term and high risk, in the worst case you could lose all of your investment.",
    info5: "¡Don't forget to diversify!",
    keepinmind: "Keep in mind",
    auxTextList4: "If you already have an investment in this round and want to purchase more packages, go to ",
    auxTextList5: "My investments ",
    auxTextList6: "and select the increase option.",
    auxTextList7:
      "If you are not a qualified investor, you may invest up to 20% of your annual income or assets, whichever is greater.",
    dinamicCopiesInvestment:{
        copy1_new_2: "How much are you going to invest?",
        copy2_new_2: "select the number of packages you want to invest in",
        copy1_new_3: "POR ASIGNAR",
        copy2_new_3: "POR ASIGNAR",
        copy1_update_2: "How much do you want to increase?",
        copy2_update_2: "select the number of packages you want to increase your investment to ",
        copy1_update_3: "How much do you want to increase?",
        copy2_update_3: "tell us the amount you want to increase in your investment to "
      }
  },
  investmentSucess: {
    title: "Thank you {{ name }}!",
    subtitle:
      "Your investment has been successful, we will confirm you via email when the campaign reaches its goal",
    transaction: "Status of the transaction:"
  },
  transactionConfirmation: {
    sectionTitle: "¡Thanks, {{name}}!",
    sectionDescription:
      "Your investment has been successful, we will confirm you via email when the campaign reaches its goal.",
    sectionDescription2:
      "Your increase has been successful, we will confirm you via email when the campaign achieves its goal.",
    sectionDescription3:
      "Balance has been charged to your account, shortly you will see its status by entering ",
    sectionDescription4:
      "Your payment is very important to those who believed in your project. Remember that you can check your movements in the payment history.",
    title: "Transaction status: In process",
    title2: {
      accepted: "Transaction status: In exchange",
      approved_by_bank: "Transaction status: Approved",
      rejected: "Transaction status: Rejected",
      canceled: "Transaction status: Canceled"
    },
    description1: "CUS",
    description2: {
      investment: "Description: Investment in {{campaignName}}",
      increase: "Description: Increase in {{campaignName}}",
      recharge: "Description: Recharge My Balance",
      due_payment: "Description: {{campaignName}} Payment"
    },
    description3: "Value:",
    description4: "Transaction date:",
    myBalance: "My balance",
    showcase: "Showcase",
    history: "History"
  },
  investmentCancellation: {
    inputError: "Invalid format",
    accountError: "Invalid account",
    accountConfirmationError: "Account confirmation error",
    continue: "Continue",
    confirmAccount: "Confirm in account number",
    accountNumber: "Account number",
    accountType: "Account Type",
    selectYourBank: "Choose your bank",
    bankingData: "Bank details",
    amountToInvest: "Amount to be returned",
    refundInformation: "Return of your investment",
    anyBank:
      "Being transparent with your money is the most important thing, tell us the following information to make the return effective"
  },
  cancellationModal: {
    text:
      "Your investment is very important for this project, we respect your deciscion to cancel it, ",
    question: "but are you sure you want to do it?",
    sure: "I'm sure"
  },
  cancellationModalSuccess: {
    title: "Successful cancellation!",
    text:
      ", your cancellation has been successful. To see your new available balance go to ",
    link: "my balance"
  },
  moneyWithdrawalModal: {
    text: "Remember that the bank will apply the 4X1000 on this movement.",
    question: "Are you sure to withdraw your money?",
    sure: "I'm sure"
  },
  identityValidationOtp: {
    title: "Validate your identity",
    message: "Enter the code we have sent to your phone number.",
    noReceived: "I have not received the code.",
    askAgain: "Request again"
  },
  contactForm: {
    validations: {
      required: "This field is required",
      email: {
        required: "This field is required",
        invalidFormat: "Must be a valid email"
      },
      phone: {
        invalidFormat: "Must be a valid number phone"
      }
    },
    send: "Send",
    tab1Title: "I want more info",
    tab2Title: "I am a PYME",
    tab2Subtitle: "How can I found?"
  },
  hero: {
    heading: {
      normal: "Meet your growth goals with",
      bold: "a2censo"
    },
    investors: "Investors",
    investments: "Investments",
    millionsFinanced: "Millions financed",
    succesfulCampaigns: "Successful campaigns",
    header: {
      item1: "a2censo",
      item2: "Community",
      item3: "Login",
      item4: "Sign up"
    },
    tabs: {
      item1: "Invest",
      item2: "Finance yourself"
    }
  },
  campaignFilter: {
    total: "Campaigns total: ",
    placeholder: "Search Campaign...",
    filters: "Filters",
    filterBy: "Filter by:",
    restoreBtn: "Restore",
    filterBtn: "Filter"
  },
  landing: {
    news: "News",
    moreInfo: "Know us",
    showcase: "Campaigns",
    crowdfunding: "Crowdfunding"
  },
  qualifiedInvestor: {
    title1: "Perfect!",
    title2: "We are ready!",
    subtitle1: "Your request is being processed.",
    subtitle2: "Your request has been successfully processed.",
    know: "Back to home"
  },
  risk: {
    Visionary: "Visionary",
    Innovator: "Innovator",
    Sustainable: "Sustainable",
    Classic: "Classic"
  },
  validateCode: {
    oopsMessage:
      "The time for the validation of your email has passed, contact us for more information."
  },
  balance: {
    availableCash: "Available",
    loadBalance: "Load",
    withdrawals: "withdrawals"
  },
  myBalance: {
    availableCash: "Available: ",
    availableExchange: "To confirm: ",
    investmentCash: "Invested: ",
    investmentExchange: "To confirm: "
  },
  moneyWithdrawal: {
    title: "Withdrawal of your money",
    description:
      "Being transparent with your money is the most important, indicate the following data to make effective the withdrawal of your money.",
    amount: "Amount to withdraw",
    personType: "Person type",
    bankInfo: "Bank data",
    bankNumber: "Bank account number *",
    bankName: "Banking entity *",
    bankAccountType: "Account type *",
    saveAndContinue: "Continue",
    select: "Select",
    confirmBankNumber: "Confirm account number *",
    companyName: "Company name",
    names: "Names",
    firstLastName: "First last name",
    secondLastName: "Second last name",
    documentType: "Document type",
    documentNumber: "Document number",
    confirmDocumentNumber: "Confirm documment number",
    accountHolder: "Account holder",
    validations: {
      number: "Must be a numeric value",
      amount: {
        invalidAmount:
          "The amount must be greater than zero(0) and cannot exceed your available balance"
      },
      bankNumber: {
        required: "Bank account number is mandatory.",
        min: "The account number is a minimum of 6 digits",
        max: "The account number is a maximum of 30 digits"
      },
      confirmBankNumber: {
        required: "Confirm account number is mandatory.",
        oneOf: "Account number must match",
        min: "The account number is a minimum of 6 digits",
        max: "The account number is a maximum of 30 digits"
      },
      names: {
        required: "Names field is mandatory."
      },
      firstLastName: {
        required: "First Last Name field is mandatory"
      },
      secondLastName: {
        required: "Second Last Name field is mandatory"
      },
      documentType: {
        required: "Document type field is mandatory."
      },
      documentNumber: {
        required: "Document number field is mandatory"
      },
      confirmDocumentNumber: {
        oneOf: "Document numbers must match",
        required: "Confirm documment number field is mandatory"
      }
    },
    footer:
      "Recuerda que el proceso de retiro de recursos de a2censo puede tardar hasta 2 días hábiles en verse reflejado en tu cuenta bancaria.",
    descriptionFooter1:
      "Las transacciones que realices antes de las 3:00 pm serán procesadas el mismo día, sin embargo todas las transacciones realizadas fuera de este horario serán procesadas el siguiente día hábil.",
    descriptionFooter2: "",
    descriptionFooter3: ""
  },
  recharge: {
    title: "¿Cuánto vas a recargar?",
    subTitle: ", indícanos el monto que quieres cargar a tu disponible.",
    amountToRechargeTitle: "Monto a recargar",
    amountPlaceHolder: "Ingrese un monto",
    tooltip: {
      title: "Recuerda que:",
      message1: "Lorem ipsum"
    },
    continue: "Continuar",
    loading: "Cargando",
    validations: {
      amount: {
        required: "The amount is required",
        invalidAmount: "Invalid amount",
        invalidAmount2:
          "The amount to recharge cannot exceed your assets or income",
        min: "You must enter an amount"
      }
    }
  },
  rechargeSummary: {
    oneStepAhead: "One step closer",
    subtitle:
      ", if you agree with the charging data to your account we will contact you with the services of PSE to finish the process.",
    rechargeToAvailable: "Load to available",
    recharge: "Load",
    loading: "Loading",
    validations: {
      bank: {
        required: "The bank is required"
      },
      documentType: {
        required: "The document type is required"
      },
      documentNumber: {
        required: "The document number is required"
      },
      amount: {
        required: "The amount is required",
        min: "Invalid amount",
        invalidAmount: "Invalid amount"
      },
      dv: {
        min: "The min value is 0",
        max: "The max value is 9"
      },
      number: "The field must be numeric"
    },
    placeholder: {
      documentType: "",
      dv: "DV",
      listOfBanks: "Select bank",
      documentNumber: "Document Number"
    }
  },
  moneyWithdrawalConfirmation: {
    sectionTitle: "Withdrawal in process",
    sectionDescription:
      ", we have received your withdrawal request, we will confirm you via email when it is effective.",
    title: "Transaction status: In process",
    description1: "CUS",
    description2: "Description: Return to Acc.",
    description3: "Value Withdrawn:",
    description4: "Retention 4X1000:",
    description5: "Total to return:",
    description6: "Request date:",
    continue: "My balance",
    bankNanme: "Bank: ",
    states: {
      pending: "Pending",
      pending_to_disperse: "Pending to disperse",
      rejected: "Rejected",
      approved: "Approved"
    },
    accountTypes: {
      savings: "Savings",
      regular: "Current"
    }
  },
  thankYou: {
    title: "¡Thank you!",
    subtitle: "Succesful transaction",
    pymeSubtitle:
      "Your payment is very important for who believed in your project",
    paragraph:
      'In a period of 30 minutes you can see from "My investments" the result of this movement.',
    pymeParagraph:
      'In a period of 30 minutes you can see from "Payment history" the result of this movement.',
    myBalance: "My balance",
    continue: "Continue",
    history: "History"
  },
  myMovements: {
    states: {
      pending: " (Pending)",
      accepted: " (Exchange)",
      approved_by_bank: " (Approved)",
      canceled: " (Rejected)",
      rejected: " (Rejected)"
    },
    searchPlaceholder: {
      title: "We haven't found results",
      subtitle: "Change your selection for best results."
    }
  },
  paginator: {
    pageOf: "page of"
  },
  movements: {
    title: "My movements",
    loading: "loading..."
  },
  accountRecovery: {
    title: "Reactive your account",
    subtitle:
      "We will send you a code to validate your identity. choose an option to recipt it.",
    sms: "Send sms",
    email: "Send email",
    emailSubtitleInit: "Email registered on ",
    emailSubtitleBold: "a2censo",
    emailSubtitleEnd: " to continue.",
    continueButton: "Continue",
    cancelLink: "Cancel"
  },
  prepaidDestiny: {
    title: "¿What do you want to do with your subscription?",
    subTitle:
      ", Your subscription can direct you to two specific purposes, please tell us the one that best suits your needs.",
    reduceQuota: {
      title: "Reduce the amount of fees",
      description:
        "Depending on the total of your payment we will re-calculate the installments initially agreed and notify your investors."
    },
    reduceTime: {
      title: "Reduce the payment value",
      description:
        "Your payment will be made in order to adjust the value of your minimum payment for the following installments."
    },
    continue: "Continue",
    loading: "loading...",
    errorByTime: {
      title: "Request Error"
    },
    fileName: "patrimonial rights",
    modal: {
      text1: "You have been seleccted",
      text2: ". Remember that you can't change this option later"
    }
  },
  timeLine: {
    timeToPay: "You have {{ day }} {{ days }} left to pay",
    deadLine: "You are on the payment deadline.",
    day: "day",
    daysText: "days",
    dayText: "day",
    timeFinished: "Your time to pay have finished"
  },
  payment: {
    minimumAmount: "Minimum payment",
    minimumAmountText:
      "It refers to the minimum amount you must pay as awarded.",
    paymentValueText: "Value to pay in COP",
    otherAmount: "Other amount",
    otherAmountText:
      "Here you can pay more than the minimum payment of your debt.",
    date: "Payday limit: "
  },
  requestSendModal: {
    title: "Request sent!",
    description:
      ", your request has been received. To see your application status go to ",
    myPayments: "My payments"
  },
  errorCodes: {
    423: "Amount entered is less than the parameter of the first investment (200,000)",
    424: "Amount entered is not multiple of 100,000 (first investment)",
    425: "Last investor must invest the value required to close the investment.",
    426: "The investment amount is not multiple of 50,000 (second investment and increased investment)",
    427: "He is the only user and wants to invest all the requested capital",
    428: "Remaining campaign less than the minimum amount",
    429: "The investor has already reached his investment limit established by the decree.",
    430: "The investor already has an investment on this campaign.",
    431: "Can't increase investment because the investment it's not approved.",
    432: "The investor has selected the total requested amount.",
    433: "The investor wanna increase your investment up to the total requested by the campaign.",
    20: "Thanks for waiting. In a moment we will load the information.",
    21: "Apparently something went wrong with your answers. Please try again.",
    22: "If you want to consult the data entered in the link, please contact us",
    23: "We cannot validate the information of the legal representative. Please contact us.",
    25: "We cannot validate the information of one or more partners. Please contact us.",
    26: "We are having problems confirming your financial information. Please try another time.",
    27: "We were unable to save your information, please enter it again to continue."
  },
  myPayments: {
    title: "My payments",
    subtitle:
      ", here you can make the payment of your debt in an agile way, exploring different options according to your needs. Remember to make your payments within the stipulated times to avoid falling into default. ",
    link: "Payment history.",
    campaign: "Campaign ",
    campaign2: "Campaign: ",
    noPendingPayments: "You have no pending payments yet.",
    notifyEmail: "We will notify you by mail.",
    button: "Pay",
    warningIcon: "!",
    warning: "Payment in default",
    warning2: "You have exceeded the payment deadline",
    warning3:
      "From this moment on, the commercial collection process will be executed directly with Covinoc in accordance with the provisions of our circular.",
    warning4: "Without details",
    warning5: "Payment details are not yet available",
    warning6:
      "We will be loading the payment details as soon as possible. Contact us if you have concerns.",
    validations: {
      amount: {
        invalid: "Invalid amount"
      }
    },
    issuerPayment: {
      approved_by_timeout: "Pending payment",
      exchange: "In exchange",
      inactive: "Inactive",
      issuer_approved: "Pending payment",
      late_payment: "Payment in default",
      paid: "Payment successful",
      paid_by_collection_house: "Payment for COVINOC",
      pending_approve: "Pending approval {{name}}",
      pending_new_amount_request: "Pending approval request other amount",
      pending_reject_request: "Pending approval of rejection request",
      rejected: "Payment declined"
    }
  },
  paymentHistoryCard: {
    due: "# Cuota",
    date: "Fecha: ",
    minimumAmount: "minimum amount",
    paidTotalAmount: "paid total amount",
    paymentByCapital: "payment by capital",
    paymentByInterest: "payment by interest",
    retentionValue: "retention value",
    bvcComission: "bvc comission",
    sharpReferenceNumber: "# reference",
    fngComission: "fng comission",
    successFee: "success fee",
    otherPayments: "other payments",
    stringReferenceNumber: "reference number"
  },
  inputFile: {
    text: "Upload document"
  },
  paymentDetail: {
    title: "Approve",
    subtitle:
      ", you will find the total detail of your payment and withholdings for approval.",
    text:
      "Remember that the maximum approval period will be up to two days before the deadline, if by that date you have not made your approval, it will be understood that you agree with the information.",
    fileName: "derecho patrimonial.xlx",
    modal: {
      text1:
        "You are going to approve, remember that you can't reject this confirmation"
    }
  },
  downloadFile: {
    buttonName: "Download"
  },
  errorModal: {
    title: "¡We are sorry!"
  },
  confirmPayAmount: {
    title: "Confrmation of Data",
    description:
      ", If you agree with the details of your investment, we will contact you with the services of ",
    description2: "PSE",
    description3: " to finish the process",
    titleSection: "Minimum amount",
    continue: "Continue",
    loading: "loading...",
    descriptionPayInit: "Payment will be made using the services of ",
    descriptionPayApp: "PSE",
    descriptionPayEnd:
      " and it will be debited from your checking or savings account",
    validations: {
      bank: {
        required: "The bank is required"
      },
      documentType: {
        required: "The document type is required"
      },
      documentNumber: {
        required: "The document number is required"
      },
      amount: {
        required: "The amount is required",
        min: "Invalid amount",
        invalidAmount: "Invalid amount"
      },
      dv: {
        min: "The min value is 0",
        max: "The max value is 9"
      },
      number: "The field must be numeric"
    },
    placeholder: {
      documentType: "",
      dv: "DV",
      listOfBanks: "Select bank",
      documentNumber: "Document Number"
    }
  },
  simulatorNotAvailable: {
    title: "¡We are updating!",
    subtitle:
      "Soon you will be able to simulate your investment including all the conditions stipulated in the campaign.",
    buttom: "It is understood"
  },
  investor: {
    title: "Liquidation rights settlement report",
    investorTitle: "Investor",
    operation: "Cap. + int.s - retention",
    qualityTax: "Quality tax",
    paymentByCapital: "Payment by Cap.",
    nit: "NIT.",
    paymentByInterest: "Payment by interests",
    accountNumber: "Account number",
    retentionValue: "Retention value",
    retentionPercentage: "% retention",
    retentionBase: "Retention base",
    valueToPay: "Value to pay",
    total: "Total",
    plusSymbol: "+",
    restSymbol: "-",
    percentageSymbol: "%",
    qualityTaxType: {
      no_quality: "No tax quality",
      great_contributor: "Great contributor",
      no_contributor: "No contributor",
      self_retainer: "Self retainer",
      declarant: "Declarant",
      no_declarant: "No declarant"
    },
    accountType: {
      ahorros: "Savings account",
      corriente: "Current account"
    }
  },
  sliderCampaings: {
    campaings: "Campaings",
    featured: " featured",
    successfull: " successfull",
    titles: {
      collected: "Collected",
      collectedAmount: "Collected Amount",
      investments: "Investments",
      days: "Days to close",
      goal: "Goal"
    }
  },
  InvestTab: {
    tab1: "Showcasetraduct",
    tab2: "Be an investor",
    tab3: "¿Why choose us?"
  },
  successfulCampaignGuide: {
    title: "Guide to a successful campaign",
    description:
      "Find a guide with recommendations to launch a successful and transparent campaign to connect with your potential investors.",
    cardTitle: "Guide for your campaign",
    download: "Download",
    showOnline: "Watch Online",
    quotesList: {
      title: "What our visionaries say",
      seeMoreTestimonials: "See more testimonials",
      seeLessTestimonials: "See less testimonials"
    }
  },
  infoSimulatorForm: {
    amount: "Amount to finance *",
    term: "Term *",
    fixedRate: "Fixed rate *",
    capitalPayment: "Capital payment",
    interestPayment: "Interest payment",
    capitalGracePeriod: "Período de gracia Capital",
    fngWarranty: "Garantía FNG",
    payPeriodically: "Pay periodically",
    payPeriodicallyGrace: "You pay periodically during the grace period",
    fees: "Fees to pay"
  },
  downloadCertificates: {
    subtitle: "We are generating your certificate",
    message: "Your download will begin shortly"
  },
  floatingButtons: {
    documents: {
      nameFloatingButton: "Documents for download",
      titleModal: "Campaign documents",
      publicationDate: "Publication date",
      downloadButton: "Download all"
    },
    simulator: {
      nameFloatingButton: "Simulate investment"
    }
  }
};
